import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/video-streaming-developer-docs/video-streaming-developer-docs/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`List all chapters of a video`}</h2>
    <p>{`Returns the details of all chapters for a specific video.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/videos/{video_id}/chapters.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with an array under a chapters key. The elements of the array contain the following key-value pairs for each video chapter:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The beginning of the chapter, provided in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "chapters": [
    {
      "id": "17878",
      "title": "First chapter",
      "position": 10
    },
    {
      "id": "17879",
      "title": "Second chapter",
      "position": 60
    }
  ]
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429 Too Many Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`too_many_requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate limit exceeded (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Add new chapter to the video`}</h2>
    <p>{`Set title and position of a new chapter for a video.`}</p>
    <pre><code parentName="pre" {...{}}>{`POST https://api.video.ibm.com/videos/{video_id}/chapters.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The parameters for the POST request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The beginning of the chapter, provided in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success, a response with HTTP status “201 Created” is returned with the following key-value pairs under a `}<inlineCode parentName="p">{`chapters`}</inlineCode>{` key.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The beginning of the chapter, provided in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429 Too Many Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`too_many_requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate limit exceeded (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Get chapter info`}</h2>
    <p>{`Returns the information on a specific chapter of a video.`}</p>
    <pre><code parentName="pre" {...{}}>{`GET https://api.video.ibm.com/videos/{video_id}/chapters/{chapter_id}.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned with the following key-value pairs under a `}<inlineCode parentName="p">{`chapter`}</inlineCode>{` key.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`KEY`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`id`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter ID`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The beginning of the chapter, provided in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Example of a success response:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
  "chapter": {
    "id": "17878",
    "title": "First chapter",
    "position": 10
  }
}
`}</code></pre>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video or chapter was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429 Too Many Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`too_many_requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate limit exceeded (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Modify video chapter`}</h2>
    <p>{`Change title or position of an existing video chapter.`}</p>
    <pre><code parentName="pre" {...{}}>{`PUT https://api.video.ibm.com/videos/{video_id}/chapters/{chapter_id}.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`The parameters for the PUT request:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`PARAMETER`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`TYPE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`IMPORTANCE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`DESCRIPTION`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`title`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`string`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Chapter title`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`position`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`integer`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`REQUIRED`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The beginning of the chapter, provided in seconds.`}</td>
        </tr>
      </tbody>
    </table>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video or chapter was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429 Too Many Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`too_many_requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate limit exceeded (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Delete video chapter`}</h2>
    <p>{`Delete an existing video chapter.`}</p>
    <pre><code parentName="pre" {...{}}>{`DELETE https://api.video.ibm.com/videos/{video_id}/chapters/{chapter_id}.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video or chapter was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429 Too Many Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`too_many_requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate limit exceeded (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>
    <h2>{`Delete all chapters of a video`}</h2>
    <p>{`Delete all existing chapters of a video.`}</p>
    <pre><code parentName="pre" {...{}}>{`DELETE https://api.video.ibm.com/videos/{video_id}/chapters.json
`}</code></pre>
    <h3>{`Parameters`}</h3>
    <p>{`This request has no parameters.`}</p>
    <h3>{`Success response`}</h3>
    <p>{`Upon success a response with HTTP status “200 OK” is returned.`}</p>
    <h3>{`Error responses`}</h3>
    <p>{`Possible error responses:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`HTTP RESPONSE CODE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR VALUE`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`ERROR CONDITIONS`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`400 Bad Request`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`invalid_value`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Validation failed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`401 Unauthorized`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`unauthorized`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The provided access token is missing, revoked, expired or malformed`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`403 Forbidden`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`lack_of_ownership`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The API user is not allowed to manage the given video`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`404 Not Found`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`not_found`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Video or chapter was not found`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`429 Too Many Requests`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`too_many_requests`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Rate limit exceeded (`}<a parentName="td" {...{
              "href": "/api-basics-rate-limits"
            }}>{`Read more`}</a>{`)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`503 Service Unavailable`}</td>
          <td parentName="tr" {...{
            "align": null
          }}></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`There is a temporary error on the server which makes it impossible to serve the request`}</td>
        </tr>
      </tbody>
    </table>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      